// formFields.js
const dataBase = {
  summary: "",
  startDate: "",
  endDate: "",
  hour: "",
  date: "",
  reservationDate: "",
  salesMan: "",
  salesManEmail: "",
  contactName: "",
  contactNumber: "",
  contactEmail: "",
  location: "",
  setupHour: "",
  package: "Special Package",
  extras: "",
  parkingDetails: "",
  vehicleAssigned: "",
  serviceType: "",
  eventContact: "",
  extraHours: 0,
  notes: "",
  status: "",
  advancePayment: 0,
  balance: 0,
  termination: 0,
  discount: 0,
  unitPricePackage: 0,
  $package: 0,
  npackage: 0,
  $hrExtra: 0,
  nhrExtra: 0,
  $chipsSalsa: 0,
  nchipsSalsa: 0,
  $chipsGuacamole: 0,
  nchipsGuacamole: 0,
  $cilantroLimeRiceBlackBeans: 0,
  ncilantroLimeRiceBlackBeans: 0,
  $driverFee: 0,
  $sundayServiceFee: 0,
  $subTotal: 0,
  $serviceFee: 0,
  $discount: 0,
  $taxes: 0,
  $total: 0,
  //   temporally
  selectedDate: null,
  selectedStartTime: null,
  selectedEndTime: null,
  image: "",
}

export default dataBase
