import React, {useState, useEffect} from "react"

import {format} from "date-fns"
import terms from "./Terms"
import {GetImage} from "../../utils/getImage"

const UserImage = (email) => {
  return GetImage(email)
}

function ordinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th"
  }
  switch (day % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

const eventColumns = [
  {
    accessorKey: "summary",
    header: terms.summary,
    size: 200,
    Cell: ({row}) => {
      const [imageUrl, setImageUrl] = useState(null)

      useEffect(() => {
        UserImage(
          row.original.description.match(
            new RegExp(`${terms.salesManEmail}: (.*)`)
          )?.[1]
        )
          .then((url) => setImageUrl(url))
          .catch((error) => console.error(error))
      }, [
        row.original.description.match(
          new RegExp(`${terms.salesManEmail}: (.*)`)
        )?.[1],
      ])

      return (
        <div style={{display: "flex", alignItems: "center"}}>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Profile"
              style={{
                width: "40px",
                height: "40px",
                marginRight: "0.5rem",
                borderRadius: "50%",
              }}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "0.5rem",
            }}
          >
            <span
              style={{
                color: "#000",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {row.original.summary}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    accessorKey: "startDate",
    header: terms.startDate,
    // size: 500,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {new Date(row.original.start.dateTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "endDate",
    header: terms.endDate,
    // size: 190,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {new Date(row.original.start.dateTime).toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
          {ordinalSuffix(new Date(row.original.start.dateTime).getDate())}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "hour",
    header: terms.hour,
    size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {new Date(row.original.start.dateTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "date",
    header: terms.date,
    // size: 190,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {new Date(row.original.start.dateTime).toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
          {ordinalSuffix(new Date(row.original.start.dateTime).getDate())}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "reservationDate",
    header: terms.reservationDate,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {format(new Date(row.original.created), "yyyy-MM-dd")}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "salesMan",
    header: terms.salesMan,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.salesMan.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "salesManEmail",
    header: terms.salesManEmail,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.salesManEmail.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "contactName",
    header: terms.contactName,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.contactName}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "contactNumber",
    header: terms.contactNumber,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.contactNumber}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "package",
    header: terms.package,
    // size: 150,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.package}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "location",
    header: terms.location,
    // size: 150,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.location}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "setupHour",
    header: terms.setupHour,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.setupHour}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },

  {
    accessorKey: "extras",
    header: terms.extras,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.extras}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "parkingDetails",
    header: terms.parkingDetails,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.parkingDetails}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "vehicleAssigned",
    header: terms.vehicleAssigned,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.vehicleAssigned}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "serviceType",
    header: terms.serviceType,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.serviceType}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "eventContact",
    header: terms.eventContact,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.eventContact}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "extraHours",
    header: terms.extraHours,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.extraHours}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "notes",
    header: terms.notes,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(`${terms.notes}: (.*)`)
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "status",
    header: terms.status,
    size: 50,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {row.original.status}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "advancePayment",
    header: terms.advancePayment,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.advancePayment.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "balance",
    header: terms.balance,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.balance.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "termination",
    header: terms.termination,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.termination.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "discount",
    header: terms.discount,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.discount.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$package",
    header: terms.$package,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$package.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "npackage",
    header: terms.npackage,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.npackage.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$hrExtra",
    header: terms.$hrExtra,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$hrExtra.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "nhrExtra",
    header: terms.nhrExtra,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.nhrExtra.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$chipsSalsa",
    header: terms.$chipsSalsa,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$chipsSalsa.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "nchipsSalsa",
    header: terms.nchipsSalsa,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.nchipsSalsa.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$chipsGuacamole",
    header: terms.$chipsGuacamole,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$chipsGuacamole.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "nchipsGuacamole",
    header: terms.nchipsGuacamole,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.nchipsGuacamole.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$cilantroLimeRiceBlackBeans",
    header: terms.$cilantroLimeRiceBlackBeans,
    // size: 280,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$cilantroLimeRiceBlackBeans.replace(
                  /[.*+?^${}()|[\]\\]/g,
                  "\\$&"
                ) + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "ncilantroLimeRiceBlackBeans",
    header: terms.ncilantroLimeRiceBlackBeans,
    // size: 280,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.ncilantroLimeRiceBlackBeans.replace(
                  /[.*+?^${}()|[\]\\]/g,
                  "\\$&"
                ) + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$driverFee",
    header: terms.$driverFee,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$driverFee.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$sundayServiceFee",
    header: terms.$sundayServiceFee,
    // size: 230,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$sundayServiceFee.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$subTotal",
    header: terms.$subTotal,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$subTotal.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$serviceFee",
    header: terms.$serviceFee,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$serviceFee.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$discount",
    header: terms.$discount,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$discount.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                  ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$taxes",
    header: terms.$taxes,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$taxes.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
  {
    accessorKey: "$total",
    header: terms.$total,
    // size: 100,
    Cell: ({row}) => (
      <div>
        <span style={{color: "#6c757d", textAlign: "left"}}>
          {
            row.original.description.match(
              new RegExp(
                terms.$total.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ": (.*)"
              )
            )?.[1]
          }
        </span>
      </div>
    ),
  },
]

export default eventColumns
