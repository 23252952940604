import Logo4 from "../../assets/Logo4.jpeg"

export const Landing = () => {
  return (
    <div className="logo-container">
      <img
        src={Logo4}
        alt="Logo"
        className="logo4"
        style={{
          opacity: 0.2,
          // width: "50%",
          animation: "logo-scale 3s infinite",
          width: "45%",
        }}
      />
    </div>
  )
}
