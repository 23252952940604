import React from "react"
import {Snackbar} from "@mui/material"
import {Alert} from "@mui/material"
import Slide from "@mui/material/Slide"

const SuccessSnackbar = ({showAlert, handleClose, msg, status}) => {
  return (
    <Snackbar
      anchorOrigin={{vertical: "bottom", horizontal: "left"}}
      open={showAlert}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={handleClose}
        severity={status}
        sx={{
          width: "100%",
          // backgroundColor: status === "error" ? "#e83348" : "#32cd322e",
          // color: "black",
          // fontWeight: "light",
        }}
      >
        {msg}
      </Alert>
    </Snackbar>
  )
}

export default SuccessSnackbar
