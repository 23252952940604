import React from "react"
import {Box} from "@mui/material"
import {useWindowDimensions} from "../../utils/getWindows"

export const Coupons = () => {
  const {height} = useWindowDimensions()
  const divHeight = `${height - 120}px`
  return (
    <div>
      <Box
        sx={{
          borderRadius: "20px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          overflow: "hidden",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease-in-out",
          minHeight: divHeight,
          maxHeight: divHeight,
        }}
      >
        <iframe
          src="https://calendar.google.com/calendar/embed?src=c_23c62d9fff414e2cc457d33721fa48ed52fa30297197a4479dedeecd48cce7e2%40group.calendar.google.com&ctz=America%2FDenver"
          style={{
            // clipPath: "inset(0 0 25px 0)",
            width: "100%",
            height: `${height - 84}px`,
            marginTop: "-15px",
            // paddingTop: "25px",
            // paddingBottom: "25px",
          }}
        ></iframe>
      </Box>
    </div>
  )
}
