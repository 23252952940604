const terms = {
  package: "PACKAGE",
  summary: "EVENT TITLE",
  location: "LOCATION",
  startDate: "START DATE",
  endDate: "END DATE",
  hour: "HOUR",
  date: "DATE FROM 2023",
  reservationDate: "RESERVATION DATE",
  salesMan: "SALES MAN",
  salesManEmail: "SALES MAN EMAIL",
  contactName: "CONTACT NAME",
  contactNumber: "CONTACT NUMBER",
  contactEmail: "CONTACT  EMAIL",
  // setupHour: "SET UP HOUR",
  extras: "EXTRAS",
  // parkingDetails: "PARKING DETAILS",
  // vehicleAssigned: "VEHICLE ASSIGNED",
  // serviceType: "SERVICE TYPE",
  eventContact: "EVENT CONTACT",
  extraHours: "EXTRA HOURS",
  // notes: "NOTES",
  status: "STATUS",

  discount: "% DISCOUNT",
  unitPricePackage: "UNIT PRICE PCKG",
  $package: "$ PACKAGE",
  npackage: "# PACKAGE",
  $hrExtra: "$ HR EXTRA",
  nhrExtra: "# HR EXTRA",
  $chipsSalsa: "$ CHIPS % SALSA",
  nchipsSalsa: "# CHIPS % SALSA",
  $chipsGuacamole: "$ CHIPS & GUACAMOLE",
  nchipsGuacamole: "# CHIPS & GUACAMOLE",
  $cilantroLimeRiceBlackBeans: "$ CILANTRO LIME RICE & BEANS",
  ncilantroLimeRiceBlackBeans: "# CILANTRO LIME RICE & BEANS",
  $driverFee: "$ DRIVER FEE",
  $sundayServiceFee: "$ SUNDAY SERVICE FEE",
  $subTotal: "$ SUB TOTAL",
  $serviceFee: "$ SERVICE FEE",
  $discount: "$ DISCOUNT",
  $taxes: "$ TAXES",
  $total: "$ TOTAL",
  advancePayment: "$ ADVANCE PAYMENT",
  balance: "$ BALANCE",
  termination: "$ TERMINATION",
  // image: "Sales Man Image",
}

export default terms
