import React, {Fragment, useState, useEffect} from "react"
// import {getEventList} from "../components/utils/getEventList"
import {Header} from "../components/home/Header"
import {Body} from "../components/home/Body"

export const Home = ({handleLogout}) => {
  const [currentTab, setCurrentTab] = useState(() => {
    return localStorage.getItem("currentTab") || "landing"
  })

  useEffect(() => {
    localStorage.setItem("currentTab", currentTab)
  }, [currentTab]) // La dependencia hace que useEffect se ejecute cada vez que currentTab cambie

  return (
    <Fragment>
      <Header
        handleLogout={handleLogout}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab} // Añade esta línea
      />

      <Body currentTab={currentTab} />
    </Fragment>
  )
}
