const base64ToUrl = (base64Data) => {
  const base64Url = base64Data
    .replace(/_/g, "/")
    .replace(/-/g, "+")
    .replace(/\*/g, "=")
    .replace(/\./g, "")

  return `data:image/jpeg;base64,${base64Url}`
}

export const GetImage = async (email) => {
  const accessToken = localStorage.getItem("token")
  try {
    const response = await fetch(
      `https://admin.googleapis.com/admin/directory/v1/users/${encodeURIComponent(
        email
      )}/photos/thumbnail`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    const data = await response.json()

    if (response.ok) {
      const photoData = data.photoData
      const imageUrl = base64ToUrl(photoData)
      return imageUrl
    }
  } catch (error) {
    console.error("Error al obtener la imagen de perfil", error)
  }

  return null
}
