import React from "react"
import {useWindowDimensions} from "../../utils/getWindows"

export const ReportPLOrem = () => {
  const {height} = useWindowDimensions()
  const divHeight = `${height - 55}px`
  return (
    <div
      style={{
        clipPath: "inset(0 0 37px 0)",
        height: divHeight,
        marginTop: "-15px",
      }}
    >
      <iframe
        title="Reports"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiMTk4ZDAxZDYtZTAwNy00ZjcwLWFmYzktZGU1NWMyNmIzMDQxIiwidCI6IjFjNWZjMzlhLTM3YjYtNGIxYy1iYWZlLTNiNWMwNDExNDQ2YSIsImMiOjR9"
        allowFullScreen={true}
        style={{width: "100%", border: "none"}}
      ></iframe>
    </div>
  )
}

// export const ReportPL = ({setCurrentTab}) => {
//   return (
//     <div style={{height: "100vh", clipPath: "inset(0 0 37px 0)"}}>
// <iframe
//   title="Reports"
//   width="100%"
//   height="100%"
//   src="https://app.powerbi.com/view?r=eyJrIjoiMTg5MGFjOWMtZmU2Mi00YmY2LWI1MTEtMTJhNjlkZWRjMWQxIiwidCI6IjFjNWZjMzlhLTM3YjYtNGIxYy1iYWZlLTNiNWMwNDExNDQ2YSIsImMiOjR9"
//   allowFullScreen={true}
//   frameBorder="0"
//   style={{clipPath: "inset(0 0 37px 0)", width: "100%", border: "none"}}
// ></iframe>

//       <button
//         style={{
//           position: "absolute",
//           top: "10px",
//           right: "10px",
//           backgroundColor: "#ffffff",
//           border: "none",
//           cursor: "pointer",
//           fontSize: "16px",
//           display: "flex",
//           alignItems: "center",
//           padding: "4px",
//           borderRadius: "5px",
//           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
//         }}
//         onClick={() => setCurrentTab("landing")}
//       >
//         <span
//           style={{
//             display: "flex",
//             alignItems: "center",
//             marginRight: "5px",
//           }}
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="20"
//             height="20"
//             viewBox="0 0 17 17"
//             fill="currentColor"
//           >
//             <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
//           </svg>
//         </span>
//         <span style={{verticalAlign: "middle"}}>Regresar</span>
//       </button>
//     </div>
//   )
// }
