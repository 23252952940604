import axios from "axios"
import {getNewToken} from "../utils/getNewToken"

export const getEventList = async () => {
  const storedToken = await getNewToken()
  // console.log("bbb:" + storedToken)

  try {
    const userInfoResponse = await axios.get(
      "https://www.googleapis.com/calendar/v3/calendars/c_23c62d9fff414e2cc457d33721fa48ed52fa30297197a4479dedeecd48cce7e2@group.calendar.google.com/events?orderBy=startTime&singleEvents=true",
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          Accept: "application/json",
        },
      }
    )

    const eventList = userInfoResponse.data.items
    // console.log(eventList)
    // const mappedEventList = convertList(eventList)
    // console.log(mappedEventList)
    return eventList
  } catch (error) {
    console.log("Failed to get user info:", error)
  }
}
