import React from "react"
import {useWindowDimensions} from "../../utils/getWindows"

export const ReportPLTrucks = () => {
  const {height} = useWindowDimensions()
  const divHeight = `${height - 55}px`
  return (
    <div
      style={{
        clipPath: "inset(0 0 37px 0)",
        height: divHeight,
        marginTop: "-15px",
      }}
    >
      <iframe
        title="Reports"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiZWNkMDI1MTYtZjI1Ni00ODhhLWIzZTQtZDg1NTY3ZWQyMWQ0IiwidCI6IjFjNWZjMzlhLTM3YjYtNGIxYy1iYWZlLTNiNWMwNDExNDQ2YSIsImMiOjR9"
        allowFullScreen={true}
        style={{width: "100%", border: "none"}}
      ></iframe>
    </div>
  )
}
