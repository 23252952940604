import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Divider,
} from "@mui/material"

import Terms from "./Terms"
import {format} from "date-fns"

export const DetailsEvent = (row) => {
  const {original} = row
  const {summary, start, end, created, description} = original

  const formattedStartDate = new Date(start.dateTime).toLocaleString()
  const formattedEndDate = new Date(end.dateTime).toLocaleString()
  // const formattedReservationDate = format(new Date(created), "yyyy-MM-dd")

  const details = {
    salesMan: getDescriptionValue(description, Terms.salesMan),
    salesManEmail: getDescriptionValue(description, Terms.salesManEmail),
    contactName: getDescriptionValue(description, Terms.contactName),
    contactNumber: getDescriptionValue(description, Terms.contactNumber),
    contactEmail: getDescriptionValue(description, Terms.contactEmail),
    location: getDescriptionValue(description, Terms.location),
    setupHour: getDescriptionValue(description, Terms.setupHour),
    package: getDescriptionValue(description, Terms.package),
    extras: getDescriptionValue(description, Terms.extras),
    parkingDetails: getDescriptionValue(description, Terms.parkingDetails),
    vehicleAssigned: getDescriptionValue(description, Terms.vehicleAssigned),
    serviceType: getDescriptionValue(description, Terms.serviceType),
    eventContact: getDescriptionValue(description, Terms.eventContact),
    extraHours: getDescriptionValue(description, Terms.extraHours),
    notes: getDescriptionValue(description, Terms.notes),
    status: getDescriptionValue(description, Terms.status),
    advancePayment: getDescriptionValue(
      description,
      Terms.advancePayment.replace(/\$/g, "\\$")
    ),
    balance: getDescriptionValue(
      description,
      Terms.balance.replace(/\$/g, "\\$")
    ),
    termination: getDescriptionValue(
      description,
      Terms.termination.replace(/\$/g, "\\$")
    ),
    discount: getDescriptionValue(
      description,
      Terms.discount.replace(/%/g, "\\%")
    ),
    packageAlias: getDescriptionValue(
      description,
      Terms.$package.replace(/\$/g, "\\$")
    ),
    npackage: getDescriptionValue(
      description,
      Terms.npackage.replace(/#/g, "\\#")
    ),
    hrExtraAlias: getDescriptionValue(
      description,
      Terms.$hrExtra.replace(/\$/g, "\\$")
    ),
    nhrExtra: getDescriptionValue(
      description,
      Terms.nhrExtra.replace(/#/g, "\\#")
    ),
    chipsSalsaAlias: getDescriptionValue(
      description,
      Terms.$chipsSalsa.replace(/\$/g, "\\$")
    ),
    nchipsSalsa: getDescriptionValue(
      description,
      Terms.nchipsSalsa.replace(/#/g, "\\#")
    ),
    chipsGuacamoleAlias: getDescriptionValue(
      description,
      Terms.$chipsGuacamole.replace(/\$/g, "\\$")
    ),
    nchipsGuacamole: getDescriptionValue(
      description,
      Terms.nchipsGuacamole.replace(/#/g, "\\#")
    ),
    cilantroLimeRiceBlackBeansAlias: getDescriptionValue(
      description,
      Terms.$cilantroLimeRiceBlackBeans.replace(/\$/g, "\\$")
    ),
    ncilantroLimeRiceBlackBeans: getDescriptionValue(
      description,
      Terms.ncilantroLimeRiceBlackBeans.replace(/#/g, "\\#")
    ),
    driverFee: getDescriptionValue(
      description,
      Terms.$driverFee.replace(/\$/g, "\\$")
    ),
    sundayServiceFee: getDescriptionValue(
      description,
      Terms.$sundayServiceFee.replace(/\$/g, "\\$")
    ),
    subTotal: getDescriptionValue(
      description,
      Terms.$subTotal.replace(/\$/g, "\\$")
    ),
    serviceFee: getDescriptionValue(
      description,
      Terms.$serviceFee.replace(/\$/g, "\\$")
    ),
    discountAlias: getDescriptionValue(
      description,
      Terms.$discount.replace(/\$/g, "\\$")
    ),
    taxes: getDescriptionValue(description, Terms.$taxes.replace(/\$/g, "\\$")),
    total: getDescriptionValue(description, Terms.$total.replace(/\$/g, "\\$")),
  }

  const styles = {
    container: {
      backgroundColor: "#F2F2F2",
      padding: "20px",
    },
    heading: {
      color: "#333333",
      fontSize: "22px",
      // marginBottom: "8px",
    },
    subHeading: {
      color: "#555555",
      fontSize: "18px",
      // marginBottom: "4px",
    },
    paragraph: {
      color: "#000",
      fontSize: "12px",
      marginBottom: "8px",
    },
    strong: {
      fontWeight: "bold",
    },
    card: {
      backgroundColor: "#B3CEED",
    },
  }

  return (
    <Grid container spacing={2}>
      {/* Card Izquierda */}
      <Grid item xs={12} md={6}>
        <Card style={{backgroundColor: "#f6f5f5", height: "200px"}}>
          <CardContent>
            <CardHeader
              title={
                <span style={{fontWeight: "bold", fontSize: "18px"}}>
                  Order Details
                </span>
              }
              style={{paddingLeft: "0px", color: "#000", paddingTop: "0px"}}
            />
            <Grid container spacing={2}>
              {/* Columna 1 */}
              <Grid item xs={6}>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Package: {details.package}</strong>
                </Typography>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Extras: {details.extras}</strong>
                </Typography>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>
                    Discount: {Number(details.discount).toFixed(2)}%{" "}
                  </strong>
                </Typography>
              </Grid>
              {/* Columna 2 */}
              <Grid item xs={0}>
                <Divider orientation="vertical" style={{borderWidth: "1px"}} />
              </Grid>
              {/* Columna 3 */}
              <Grid item xs={4}>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>
                    Total Event Price: ${Number(details.total).toFixed(2)}{" "}
                  </strong>
                </Typography>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>
                    Total Deposit: ${Number(details.advancePayment).toFixed(2)}{" "}
                  </strong>
                </Typography>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>
                    Remaing Balance: ${Number(details.balance).toFixed(2)}{" "}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* Card derecha */}
      <Grid item xs={12} md={6}>
        <Card style={{backgroundColor: "#f6f5f5", height: "200px"}}>
          <CardContent>
            <CardHeader
              title={
                <span style={{fontWeight: "bold", fontSize: "18px"}}>
                  Contact
                </span>
              }
              style={{paddingLeft: "0px", color: "#000", paddingTop: "0px"}}
            />
            <Grid container spacing={2}>
              {/* Columna 1 */}
              <Grid item xs={4}>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Contact: {details.contactName}</strong>
                </Typography>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Phone: {details.contactNumber}</strong>
                </Typography>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Email: {details.contactEmail}</strong>
                </Typography>
              </Grid>
              {/* Columna 2 */}
              <Grid item xs={0}>
                <Divider orientation="vertical" style={{borderWidth: "1px"}} />
              </Grid>
              {/* Columna 3 */}
              <Grid item xs={6}>
                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Address: {details.location} </strong>
                </Typography>

                <Typography variant="body1" style={styles.paragraph}>
                  <strong>Start Date: {formattedStartDate} </strong>
                </Typography>

                <Typography variant="body1" style={styles.paragraph}>
                  <strong>End Date: {formattedEndDate} </strong>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const getDescriptionValue = (description, term) => {
  const regex = new RegExp(`${term}: (.*)`)
  const match = description.match(regex)
  return match ? match[1] : ""
}
