import React from "react"
import Dialog from "@mui/material/Dialog"
// import Draggable from "react-draggable"
import Paper from "@mui/material/Paper"
import {Box, IconButton, Divider, Typography} from "@mui/material"
import DialogContentText from "@mui/material/DialogContentText"
import logo1 from "../../../assets/Logo2.png"
import DownloadIcon from "@mui/icons-material/Download"
import domtoimage from "dom-to-image"

function PaperComponent(props) {
  return (
    // <Draggable
    //   handle="#draggable-dialog-title"
    //   cancel={'[class*="MuiDialogContent-root"]'}
    // >
    <Paper {...props} id="dialog-container" />
    // </Draggable>
  )
}

export const ResultCalculate = ({open, setOpen, formData, selectedPackage}) => {
  const fontSizeForm = "14px"

  const captureDialog = () => {
    const dialog = document.getElementById("dialog-container")
    const dialogWidth = dialog.offsetWidth + 65
    const dialogHeight = dialog.offsetHeight + 65
    domtoimage
      .toPng(dialog, {width: dialogWidth, height: dialogHeight})
      .then(function (dataUrl) {
        const link = document.createElement("a")
        link.href = dataUrl
        link.download = "dialog.png"
        link.click()
      })
      .catch(function (error) {
        console.error("Error capturing dialog:", error)
      })
  }

  const subTotalCost = formData.$subTotal

  const subTotalServiceFee = formData.$serviceFee

  const subTotalDiscount = formData.$discount

  const subTotalTaxes = formData.$taxes

  const total = formData.$total

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        // aria-labelledby="draggable-dialog-title"
      >
        {/* TITLE */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "30px",
            marginBottom: "10px",
            // cursor: "move",
            width: "580px",
          }}
          // id="draggable-dialog-title"
        >
          <img
            src={logo1}
            alt="Visual event"
            width="160px"
            height="auto"
            style={{
              marginLeft: "20px",
            }}
          />
          <DialogContentText
            style={{
              fontSize: "16px",
              marginRight: "0px",
              flex: 1,
              textAlign: "right",
              fontWeight: "bold",
            }}
          >
            | EVENT QUOTATION
          </DialogContentText>
        </div>

        {/* TITLE */}
        <Box
          display="flex"
          style={{
            backgroundColor: "#C73949",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginTop: "5px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "white",
              fontWeight: "bold",
              fontSize: fontSizeForm,
            }}
          >
            DESCRIPTION
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              marginTop: "5px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              color: "white",
              fontWeight: "bold",
              fontSize: fontSizeForm,
            }}
          >
            $
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              marginTop: "5px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              color: "white",
              fontWeight: "bold",
              fontSize: fontSizeForm,
            }}
          >
            #
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginTop: "5px",
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              color: "white",
              fontWeight: "bold",
              fontSize: fontSizeForm,
            }}
          >
            TOTAL
          </DialogContentText>
        </Box>

        {/* DIVIDER */}
        <Divider
          style={{
            marginLeft: "20px",
            marginBottom: "5px",
            marginRight: "20px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
          }}
        />

        {/* PACKAGE */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {formData.package}
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            $
            {(formData.package === "Package 5"
              ? 3995
              : Number(formData.unitPricePackage)
            ).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {formData.npackage === "" ? 0 : formData.npackage}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            $
            {(formData.package === "Package 5"
              ? 3995
              : Number(formData.npackage) * formData.unitPricePackage
            ).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* HR EXTRA */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Hr Extra
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(150).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {formData.nhrExtra === "" ? 0 : formData.nhrExtra}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$hrExtra).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* CHIPS AND SALSA */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Chips & Salsa
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(2.25).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {formData.nchipsSalsa === "" ? 0 : formData.nchipsSalsa}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$chipsSalsa).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* CHIPS AND GUACAMOLE */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Chips & guacamole
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(2.75).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {formData.nchipsGuacamole === "" ? 0 : formData.nchipsGuacamole}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$chipsGuacamole).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* CILANTRO LIMA RICE BLACK BEANS */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Cilantro, lime, rice & black beans
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(2.5).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {formData.ncilantroLimeRiceBlackBeans === ""
              ? 0
              : formData.ncilantroLimeRiceBlackBeans}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            $
            {(
              Number(formData.$cilantroLimeRiceBlackBeans) * Number(2.5)
            ).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* DRIVER FEE */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Driver Fee
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$driverFee).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {"-"}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$driverFee).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* SUNDAY SERVICE FEE */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Sunday Service Fee
          </Typography>

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$sundayServiceFee).toFixed(2)}
          </DialogContentText>

          <DialogContentText
            style={{
              marginLeft: "25px",
              //   flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "55px",
              fontSize: fontSizeForm,
            }}
          >
            {"-"}
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${Number(formData.$sundayServiceFee).toFixed(2)}
          </DialogContentText>
        </Box>

        {/* SUB TOTAL */}
        <Divider
          style={{
            marginLeft: "25px",
            marginBottom: "5px",
            marginRight: "30px",
            borderBottom: "2px solid black",
          }}
        />

        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          />

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
              fontWeight: "bold",
            }}
          >
            SUB TOTAL
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${subTotalCost.toFixed(2)}
          </DialogContentText>
        </Box>

        {/* SUB TOTAL: SERVICE FEE */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          />

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            SERVICE FEE
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${subTotalServiceFee.toFixed(2)}
          </DialogContentText>
        </Box>

        {/* SUB TOTAL: DISCOUNT */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          />

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            DISCOUNT
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${subTotalDiscount.toFixed(2)}
          </DialogContentText>
        </Box>

        {/* SUB TOTAL: TAXES */}
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          />

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            TAXES
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontSize: fontSizeForm,
            }}
          >
            ${subTotalTaxes.toFixed(2)}
          </DialogContentText>
        </Box>

        {/* TOTAL */}
        <Divider
          style={{
            marginLeft: "300px",
            marginBottom: "5px",
            marginRight: "30px",
            borderBottom: "2px solid black",
          }}
        />
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography
            variant="body1"
            align="left"
            style={{
              marginLeft: "20px",
              marginRight: "25px",
              textAlign: "left",
              width: "240px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          />

          <DialogContentText
            style={{
              marginLeft: "25px",
              flex: 1,
              marginBottom: "5px",
              textAlign: "left",
              width: "100px",
              fontWeight: "bold",
              // fontSize: "14px",
              fontSize: fontSizeForm,
            }}
          >
            TOTAL
          </DialogContentText>

          <DialogContentText
            style={{
              flex: 1,
              marginBottom: "5px",
              marginRight: "20px",
              textAlign: "right",
              width: "100px",
              fontWeight: "bold",
              fontSize: fontSizeForm,
            }}
          >
            ${total.toFixed(2)}
          </DialogContentText>
        </Box>

        <Box sx={{marginLeft: "20px", marginBottom: "5px"}}>
          <IconButton
            disabled={false}
            onClick={captureDialog}
            style={{marginLeft: "8px"}}
          >
            <DownloadIcon sx={{color: "#888"}} />
          </IconButton>
        </Box>
      </Dialog>
    </div>
  )
}
