import React, {useState, useEffect, useCallback} from "react"
import {getEventList} from "../../utils/getEventList"
import {MaterialReactTable} from "material-react-table"
import {Box, IconButton, Tooltip} from "@mui/material"
// import FileDownloadIcon from "@mui/icons-material/FileDownload"
import SyncIcon from "@mui/icons-material/Sync"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import {useWindowDimensions} from "../../utils/getWindows"
import {CalculateEvent} from "./CalculateEvent"
import {EditEvent} from "./EditEvent"
import eventColumns from "./ColumnsEvent"
import SuccessSnackbar from "./SuccessSnackbar"
import {DetailsEvent} from "./DetailsEvent"
import {ExportToCsv} from "export-to-csv"

import {Delete, Edit} from "@mui/icons-material"

import dataBase from "./dataBase"
import Terms from "./Terms"

const clickSound = new Audio(
  require("../../../assets/short-success-sound-glockenspiel-treasure-video-game-6346.mp3")
)

const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: eventColumns.map((c) => c.header),
}

const csvExporter = new ExportToCsv(csvOptions)

export const EventList = ({currentTab}) => {
  const [events, setEvents] = useState([])
  const {height} = useWindowDimensions()
  const divHeight = `${height - 242}px`
  const [showAlert, setShowAlert] = useState(false)

  const [openCalcularEvento, setOpenCalcularEvento] = useState(false)
  const [openEditEvent, setOpenEditEvent] = useState(false)

  const columns = eventColumns
  const [msg, setMsg] = useState("Events have been updated.")
  const [status, setStatus] = useState("success")
  const [eventData, setEventData] = useState({...dataBase})

  // const [columnVisibility, setColumnVisibility] = useState({})

  const getEvents = useCallback(async () => {
    if (currentTab === "eventList") {
      const fetchedEvents = await getEventList()
      setEvents(fetchedEvents)
    }
  }, [currentTab])

  useEffect(() => {
    getEvents()
  }, [getEvents])

  const handleClickCalcularEvento = () => {
    setOpenCalcularEvento(true)
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowAlert(false)
  }

  const handleUpdateEvents = async () => {
    clickSound.play()
    setMsg("Events have been updated.")
    setStatus("success")
    setShowAlert(true)
    await getEvents()
  }

  const handleErrorEvent = (msg) => {
    clickSound.play()
    setMsg(msg)
    setStatus("error")
    setShowAlert(true)
  }

  const handleCreateEvent = () => {
    clickSound.play()
    setMsg("The event has been created.")
    setStatus("success")
    setShowAlert(true)
  }

  const handleExportData = () => {
    const formattedEvents = events.map((event) => {
      const descriptionLines = event.description.split("\n")
      const formattedEvent = {}

      descriptionLines.forEach((line) => {
        const [key, value] = line.split(": ")
        formattedEvent[key] = value
      })

      return formattedEvent
    })

    csvExporter.generateCsv(formattedEvents)
  }

  const editRowEvent = (event) => {
    const reverseTerms = Object.fromEntries(
      Object.entries(Terms).map(([key, value]) => [value, key])
    )

    // console.log(event)
    const eventId = event.original.id
    const lines = event.original.description.split("\n")

    for (const line of lines) {
      const [key, value] = line.split(": ", 2)
      const dbKey = reverseTerms[key]

      if (dbKey in eventData) {
        let parsedValue = value
        if (!isNaN(value)) {
          parsedValue = parseFloat(value)
        }
        eventData[dbKey] = parsedValue
      }
    }
    eventData["id"] = eventId
    // console.log(eventData)
    setOpenEditEvent(true)
  }

  return (
    <div>
      <Box
        sx={{
          borderRadius: "20px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          overflow: "hidden",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease-in-out",
        }}
      >
        <MaterialReactTable
          editingMode="modal"
          enableEditing
          columns={columns}
          data={events}
          muiTableContainerProps={{
            sx: {minHeight: divHeight, maxHeight: divHeight},
          }}
          enablePagination={true}
          enableStickyHeader={true}
          enableColumnOrdering={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableRowNumber={false}
          enableSorting={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          // enableExpanding
          positionExpandColumn="last"
          renderDetailPanel={({row}) => DetailsEvent(row)}
          initialState={{
            columnVisibility: {
              startDate: false,
              endDate: false,
              reservationDate: false,
              salesMan: false,
              salesManEmail: false,
              contactName: false,
              contactNumber: false,
              contactEmail: false,
              setupHour: false,
              // package: false,
              extras: false,
              parkingDetails: false,
              vehicleAssigned: false,
              serviceType: false,
              eventContact: false,
              extraHours: false,
              notes: false,
              // status: false,
              advancePayment: false,
              balance: false,
              termination: false,
              discount: false,
              $package: false,
              npackage: false,
              $hrExtra: false,
              nhrExtra: false,
              $chipsSalsa: false,
              nchipsSalsa: false,
              $chipsGuacamole: false,
              nchipsGuacamole: false,
              $cilantroLimeRiceBlackBeans: false,
              ncilantroLimeRiceBlackBeans: false,
              $driverFee: false,
              $sundayServiceFee: false,
              $subTotal: false,
              $serviceFee: false,
              $discount: false,
              $taxes: false,
              $total: false,
            },
          }}
          renderTopToolbarCustomActions={({table}) => (
            <Box sx={{display: "flex", gap: "1rem", p: "4px"}}>
              {/* <Tooltip title="Exportar datos a CSV">
                <IconButton
                  sx={{color: "#B3CEED"}}
                  onClick={() => {
                    handleExportData()
                  }}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Actualizar eventos">
                <IconButton
                  sx={{color: "#B3CEED"}}
                  onClick={() => {
                    handleUpdateEvents()
                  }}
                >
                  <SyncIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Calcular evento">
                <IconButton
                  sx={{color: "#B3CEED"}}
                  onClick={() => {
                    handleClickCalcularEvento()
                  }}
                >
                  <LibraryAddIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Crear evento">
                <IconButton
                  sx={{color: "#B3CEED"}}
                  onClick={() => {
                    handleClicCreatEvento()
                  }}
                >
                  <LibraryAddIcon />
                </IconButton>
              </Tooltip> */}
            </Box>
          )}
          renderRowActions={({row, table}) => (
            <Box sx={{display: "flex", gap: "1rem"}}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={() => {
                    editRowEvent(row)
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </Box>
      <SuccessSnackbar
        showAlert={showAlert}
        handleClose={handleClose}
        msg={msg}
        status={status}
      />
      <CalculateEvent
        open={openCalcularEvento}
        setOpen={setOpenCalcularEvento}
        handleErrorEvent={handleErrorEvent}
        handleCreateEvent={handleCreateEvent}
        handleUpdateEvents={handleUpdateEvents}
      />
      <EditEvent
        open={openEditEvent}
        setOpen={setOpenEditEvent}
        handleErrorEvent={handleErrorEvent}
        handleCreateEvent={handleCreateEvent}
        handleUpdateEvents={handleUpdateEvents}
        formData={eventData}
        setFormData={setEventData}
      />
    </div>
  )
}
