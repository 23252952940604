import axios from "axios"

export const getNewToken = async () => {
  const refresh_token =
    "1//04U6v97b-NdYFCgYIARAAGAQSNwF-L9IrvkD1a98my8j6HpOQtSdODcAO3sJsr5bVtAjf2y-xzjxlIvNIAB4hQMDM5voI74hNo0U"

  // Datos necesarios para obtener un nuevo token de acceso
  const data = {
    client_id:
      "536407439868-971gqnn8j22ssj8t3j4i58hm5js1b4nr.apps.googleusercontent.com",
    client_secret: "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",
    refresh_token: refresh_token,
    grant_type: "refresh_token",
  }

  try {
    const response = await axios.post(
      "https://oauth2.googleapis.com/token",
      data
    )

    const newToken = response.data.access_token
    localStorage.setItem("token", newToken)
    // console.log("aaa:" + newToken)
    return newToken
  } catch (error) {
    console.error(error)
  }
}
