import React, {useState, useEffect} from "react"
import CancelIcon from "@mui/icons-material/Cancel"
import {
  TextField,
  MenuItem,
  IconButton,
  Divider,
  Button,
  FormHelperText,
  Select,
  Typography,
  Tooltip,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Paper from "@mui/material/Paper"
import Draggable from "react-draggable"
import {ResultCalculate} from "./ResultCalculate"

import VisibilityIcon from "@mui/icons-material/Visibility"
import RestartAltIcon from "@mui/icons-material/RestartAlt"

import {styled} from "@mui/material/styles"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"

import dayjs from "dayjs"
import {GetImage} from "../../utils/getImage"
import {pushNewEvent} from "../../utils/pushNewEvent"

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {TimePicker} from "@mui/x-date-pickers/TimePicker"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"

import dataBase from "./dataBase"

import packages from "./packages"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        sx={{
          borderRadius: "13px", // Opcional: Agrega estilos de borde redondeado
          width: "530px",
          minWidth: "530px",
        }}
      />
    </Draggable>
  )
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "&:before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: "0.9rem"}} />}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

export const CalculateEvent = ({
  open,
  setOpen,
  handleErrorEvent,
  handleCreateEvent,
  handleUpdateEvents,
}) => {
  const [formData, setFormData] = useState({...dataBase})

  const [expanded, setExpanded] = useState("panel1")
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem("userData"))
  const hourExtraPrice = 150
  const [errors, setErrors] = useState({})

  const [unitPrice, setUnitPrice] = useState(
    packages[formData.package].unitPrice
  )

  const [minConsumption, setMinConsumption] = useState(
    packages[formData.package].minConsumption
  )

  const [showResultDialog, setShowResultDialog] = useState(false)

  // Calculates
  const [subTotalPacakge, setSubTotalPacakge] = useState(0)
  const [subTotalChipsSalsa, setTotalChipsSalsa] = useState(0)
  const [subTotalChipsGuacamole, setTotalChipsGuacamole] = useState(0)
  const [
    subTotalCilantroLimeRiceBlackBeans,
    setTotalCilantroLimeRiceBlackBeans,
  ] = useState(0)
  const [subTotalHrExtra, setSubTotalHrExtra] = useState(0)

  const [subTotalCost, setSubTotalCost] = useState(0)
  const [subTotalServiceFee, setSubTotalServiceFee] = useState(0)
  const [subTotalDiscount, setSubTotalDiscount] = useState(0)
  const [subTotalTaxes, setSubTotalTaxes] = useState(0)
  const [total, setTotal] = useState(0)
  // const [depositPayment, setDepositPayment] = useState(0)
  const [remainingBalance, setRemainingBalance] = useState(0)

  useEffect(() => {
    const calculatedPackage = Number(formData.npackage) * unitPrice
    const calculatedChipsSalsa = Number(formData.nchipsSalsa) * 2.25
    const calculatedHrExtra = Number(formData.nhrExtra) * hourExtraPrice
    const calculatedChipsGuacamole = Number(formData.nchipsGuacamole) * 2.75
    const calculatedCilantroLimeRiceBlackBeans =
      Number(formData.ncilantroLimeRiceBlackBeans) * 2.5

    const calculatedSubTotalCost =
      (formData.package === "Package 5" ? 3995 : calculatedPackage) +
      calculatedHrExtra +
      calculatedChipsSalsa +
      calculatedChipsGuacamole +
      calculatedCilantroLimeRiceBlackBeans +
      Number(formData.$driverFee) +
      Number(formData.$sundayServiceFee)

    const calculatedSubTotalServiceFee = calculatedSubTotalCost * 0.25

    const calculatedSubTotalDiscount =
      calculatedSubTotalCost * 1.25 * (formData.discount / 100)

    const calculatedSubTotalTaxes =
      (calculatedSubTotalCost +
        calculatedSubTotalServiceFee -
        calculatedSubTotalDiscount) *
      0.0825

    const calculatedTotal =
      calculatedSubTotalCost +
      calculatedSubTotalServiceFee -
      calculatedSubTotalDiscount +
      calculatedSubTotalTaxes

    const afterDepositTotal = calculatedTotal - Number(formData.advancePayment)

    setSubTotalPacakge(calculatedPackage)
    setTotalChipsSalsa(calculatedChipsSalsa)
    setTotalChipsGuacamole(calculatedChipsGuacamole)
    setTotalCilantroLimeRiceBlackBeans(calculatedCilantroLimeRiceBlackBeans)

    setSubTotalHrExtra(calculatedHrExtra)

    setSubTotalCost(calculatedSubTotalCost)
    setSubTotalServiceFee(calculatedSubTotalServiceFee)
    setSubTotalDiscount(calculatedSubTotalDiscount)
    setSubTotalTaxes(calculatedSubTotalTaxes)
    setTotal(calculatedTotal)
    setRemainingBalance(afterDepositTotal)

    // console.log(formData)
  }, [formData, unitPrice, minConsumption])

  const handlePreview = () => {
    const newErrors = {}
    if (formData.npackage < minConsumption) {
      newErrors.npackage = true
      newErrors.npackageHelper = "Minimum: " + minConsumption
    } else {
      setShowResultDialog(true)
      setFormData((prevFormData) => ({
        ...prevFormData,
        $serviceFee: subTotalServiceFee,
        $discount: subTotalDiscount,
        $taxes: subTotalTaxes,
        $package: subTotalPacakge,
        $chipsSalsa: subTotalChipsSalsa,
        $chipsGuacamole: subTotalChipsGuacamole,
        $cilantroLimeRiceBlackBeans: subTotalCilantroLimeRiceBlackBeans,
        $hrExtra: subTotalHrExtra,
        $subTotal: subTotalCost,
        $total: total,
        unitPricePackage: unitPrice,
        balance: remainingBalance,
      }))
    }
    setErrors(newErrors)
  }

  const handleCreate = async (eventType) => {
    const newErrors = {}
    if (formData.npackage < minConsumption) {
      newErrors.npackage = true
      newErrors.npackageHelper = "Minimum: " + minConsumption
      setExpanded("panel1")
    } else {
      if (
        formData.selectedDate !== "null" &&
        formData.selectedStartTime < formData.selectedEndTime
      ) {
        try {
          setButtonDisabled(true)
          formData.startDate = dayjs(
            `${formData.selectedDate}T${formData.selectedStartTime}`
          ).format("YYYY-MM-DDTHH:mm:ss")
          formData.endDate = dayjs(
            `${formData.selectedDate}T${formData.selectedEndTime}`
          ).format("YYYY-MM-DDTHH:mm:ss")
          formData.hour = dayjs(
            `${formData.selectedDate}T${formData.selectedStartTime}`
          ).format("hh:mm A")
          formData.date = dayjs().format("dddd MMMM Do")
          formData.reservationDate = dayjs().format("YYYY-MM-DDTHH:mm:ss")
          formData.salesMan = userInfo.name
          formData.salesManEmail = userInfo.email
          formData.image = await GetImage(userInfo.email)
          const response = await pushNewEvent(formData, eventType)
          if (response === 200) {
            handleCreateEvent()
            handleUpdateEvents()
            handleClickClose()
          } else {
            handleErrorEvent("There is an error. Please, try again.")
          }
        } catch (error) {
          // console.log(error)
        }
      } else {
        handleErrorEvent("Please, select a valid Date.")
        setExpanded("panel3")
      }
    }
    setErrors(newErrors)
  }

  const resetExtras = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nchipsSalsa: "",
      nchipsGuacamole: "",
      ncilantroLimeRiceBlackBeans: "",
    }))
  }

  const handleClickClose = () => {
    setOpen(false)
    setFormData({...dataBase})
    setButtonDisabled(false)
    setUnitPrice(packages["Special Package"].unitPrice)
    setMinConsumption(packages["Special Package"].minConsumption)
    setExpanded("panel1")
    setErrors({})
  }

  const handleAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleChange = (event, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      $serviceFee: subTotalServiceFee,
      $discount: subTotalDiscount,
      $taxes: subTotalTaxes,
      $package: subTotalPacakge,
      $chipsSalsa: subTotalChipsSalsa,
      $chipsGuacamole: subTotalChipsGuacamole,
      $cilantroLimeRiceBlackBeans: subTotalCilantroLimeRiceBlackBeans,
      $hrExtra: subTotalHrExtra,
      $subTotal: subTotalCost,
      $total: total,
      unitPricePackage: unitPrice,
      balance: remainingBalance,
    }))

    if (event && event.$d) {
      const {$d} = event
      if (name === "selectedDate") {
        const formattedData = dayjs($d).format("YYYY-MM-DD")
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: formattedData,
        }))
        // console.log(formattedData)
      } else if (name === "selectedStartTime" || name === "selectedEndTime") {
        const formattedData = dayjs($d).format("HH:mm")
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: formattedData,
        }))
        // console.log(formattedData)
      }
    } else {
      const {name, value} = event.target
      if (event.target.name === "MenuOption1") {
        setMenuOption1(event.target.value)
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]:
            value === "" ? value : isNaN(Number(value)) ? value : Number(value),
        }))
      }
      // console.log(packages[value].minConsumption)
      if (name === "package") {
        setUnitPrice(packages[value].unitPrice)
        setMinConsumption(packages[value].minConsumption)
      }
    }
  }

  // PRUEBA
  const [MenuOption1, setMenuOption1] = useState("nchipsSalsa")

  return (
    <div>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            cursor: "move",
            color: "white",
            fontSize: "18px",
            background: "#D72239",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "60px",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
          id="draggable-dialog-title"
        >
          <span className="mt-2">Event Calculator</span>
          <IconButton
            style={{marginRight: "-7px"}}
            onClick={handleClickClose}
            onTouchStart={handleClickClose}
          >
            <CancelIcon sx={{fontSize: 30, color: "#fff"}} />
          </IconButton>
        </DialogTitle>
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            paddingTop: "15px",
            paddingLeft: "15px",
            paddingRight: "15px",
            marginRight: "0px",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          {/* Acordion Package */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordion("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Package Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Fila Package */}
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                {/* Package */}
                <TextField
                  required
                  select
                  name="package"
                  value={formData.package}
                  size="small"
                  style={{
                    width: "36%",
                  }}
                  onChange={handleChange}
                >
                  {Object.values(packages).map((option) => (
                    <MenuItem key={option.package} value={option.package}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                {/* # people */}
                <TextField
                  required
                  label="# people"
                  name="npackage"
                  value={formData.npackage}
                  size="small"
                  inputProps={{pattern: "[0-9]*"}}
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9]/g,
                      ""
                    )
                  }}
                  style={{
                    width: "100px",
                    marginLeft: "20px",
                  }}
                  onChange={handleChange}
                  error={errors.npackage}
                  helperText={
                    <FormHelperText
                      component="span"
                      sx={{
                        position: "absolute",
                        top: "36px",
                        left: "0px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {errors.npackageHelper}
                    </FormHelperText>
                  }
                />

                {/* # extra hrs */}
                <TextField
                  required
                  label="# extra hrs"
                  name="nhrExtra"
                  value={formData.nhrExtra}
                  size="small"
                  inputProps={{pattern: "[0-9.]*"}}
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9.]/g,
                      ""
                    )
                  }}
                  style={{
                    width: "100px",
                    marginLeft: "20px",
                  }}
                  onChange={handleChange}
                />

                {/* Icono View Package Summary */}
                <Tooltip title="View Package Summary" arrow>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "64px",
                      right: "15px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={handlePreview}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </div>

              {/* Elemento Package Pricing */}
              <div
                style={{
                  marginTop: "10px",
                  backgroundColor: "#F6F6F6",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  whiteSpace: "pre-line",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <b>Package Pricing</b>

                {/* Unit Price */}
                <div style={{paddingTop: "5px"}}>
                  Unit Price: <span style={{fontWeight: "bold"}}>$</span>
                  <input
                    type="number"
                    name="advancePayment"
                    value={unitPrice}
                    onChange={(e) => setUnitPrice(e.target.value)}
                    style={{
                      border: "none",
                      outline: "none",
                      fontWeight: "bold",
                      textAlign: "right",
                      width: "10%",
                      borderBottom: "1px solid black",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>

                {/* Minimum Consumption */}
                <div style={{paddingTop: "5px"}}>
                  Minimum Consumption:{" "}
                  <span style={{fontWeight: "bold"}}></span>
                  <input
                    type="number"
                    value={minConsumption}
                    onChange={(e) => setMinConsumption(e.target.value)}
                    style={{
                      border: "none",
                      outline: "none",
                      fontWeight: "bold",
                      textAlign: "right",
                      width: "10%",
                      borderBottom: "1px solid black",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>

                {/* Restart Button */}
                <div>
                  <Tooltip title="Default Pricing" arrow>
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "12px",
                        right: "10px",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={() => {
                        setUnitPrice(packages[formData.package].unitPrice)
                        setMinConsumption(
                          packages[formData.package].minConsumption
                        )
                      }}
                    >
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              {/* Elemento Extras */}
              <div style={{marginTop: "10px"}}>
                <span>
                  <b>Extras</b>
                  <br />
                </span>
                <Select
                  name="MenuOption1"
                  value={MenuOption1}
                  onChange={handleChange}
                  size="small"
                  inputProps={{"aria-label": "Without label"}}
                  style={{border: "none", outline: "none", width: "60%"}}
                >
                  <MenuItem value={"nchipsSalsa"}>
                    {formData["nchipsSalsa"] > 0 ? "*" : null} Chips and Salsa
                  </MenuItem>
                  <MenuItem value={"nchipsGuacamole"}>
                    {formData["nchipsGuacamole"] > 0 ? "*" : null} Chips and
                    guacamole
                  </MenuItem>
                  <MenuItem value={"ncilantroLimeRiceBlackBeans"}>
                    {formData["ncilantroLimeRiceBlackBeans"] > 0 ? "*" : null}{" "}
                    Cilantro lime rice & black beans
                  </MenuItem>
                </Select>
                <TextField
                  required
                  label="# person"
                  name={MenuOption1}
                  size="small"
                  value={formData[MenuOption1]}
                  inputProps={{pattern: "[0-9]*"}}
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9]/g,
                      ""
                    )
                  }}
                  style={{
                    width: "100px",
                    marginLeft: "20px",
                  }}
                  onChange={handleChange}
                />
                <Tooltip title="Default Extras" arrow>
                  <IconButton
                    sx={{
                      top: "0px",
                      right: "-17px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => resetExtras()}
                  >
                    <RestartAltIcon />
                  </IconButton>
                </Tooltip>
              </div>

              {/* Discount */}
              <div style={{marginTop: "10px", display: "flex"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "27%",
                  }}
                >
                  <span>
                    <b>Discount</b>
                  </span>
                  <TextField
                    required
                    // label="% Discount"
                    size="small"
                    placeholder="% Discount"
                    name="discount"
                    value={formData.discount}
                    inputProps={{pattern: "[0-9]*", max: "100"}}
                    onInput={(event) => {
                      let value = event.target.value
                      if (value > 100) {
                        value = "100"
                      }
                      event.target.value = value.replace(/[^0-9]/g, "")
                    }}
                    style={
                      {
                        // marginTop: "10px",
                      }
                    }
                    onChange={handleChange}
                  />
                </div>

                {/* Driving fee */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "27%",
                    marginLeft: "25px",
                  }}
                >
                  <span>
                    <b>Driving fee</b>
                  </span>
                  <TextField
                    required
                    size="small"
                    placeholder="Driver fee"
                    name="$driverFee"
                    value={formData.$driverFee}
                    inputProps={{pattern: "[0-9.]*"}}
                    onInput={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9.]/g,
                        ""
                      )
                    }}
                    style={
                      {
                        // marginRight: "25px",
                      }
                    }
                    onChange={handleChange}
                  />
                </div>

                {/* Sunday service */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "27%",
                    marginLeft: "25px",
                  }}
                >
                  <span>
                    <b>Sunday service</b>
                  </span>

                  <Select
                    required
                    size="small"
                    name="$sundayServiceFee"
                    value={formData.$sundayServiceFee}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={150}>Yes</MenuItem>
                  </Select>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Acordion Event */}
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordion("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Event Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Event Title */}
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                <TextField
                  required
                  label="Event Title"
                  name="summary"
                  value={formData.summary}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
              </div>

              {/* Elemento Date */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                {/* Date */}
                <div style={{width: "165px"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      required
                      disablePast
                      label="Date"
                      name="selectedDate"
                      slotProps={{textField: {size: "small"}}}
                      onChange={(data) => handleChange(data, "selectedDate")}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        flex: 1,
                      }}
                    />
                  </LocalizationProvider>
                </div>

                {/* Start Time */}
                <div style={{width: "135px"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      required
                      label="Start Time"
                      name="selectedStartTime"
                      slotProps={{textField: {size: "small"}}}
                      onChange={(data) =>
                        handleChange(data, "selectedStartTime")
                      }
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        flex: 1,
                      }}
                    />
                  </LocalizationProvider>
                </div>

                {/* End Time */}
                <div style={{width: "135px"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      required
                      label="End Time"
                      name="selectedEndTime"
                      slotProps={{textField: {size: "small"}}}
                      onChange={(data) => handleChange(data, "selectedEndTime")}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        flex: 1,
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              {/* Event Address */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <TextField
                  required
                  label="Event Address"
                  name="location"
                  value={formData.location}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
              </div>

              {/* Event Extras */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <TextField
                  label="Event Extras"
                  name="extras"
                  value={formData.extras}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Elemento Datos de Cliente*/}
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordion("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Contact Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Contact Name */}
              <div
                style={{
                  display: "flex",
                }}
              >
                <TextField
                  label="Contact Name"
                  name="contactName"
                  value={formData.contactName}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
              </div>

              {/* Contact Email */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <TextField
                  label="Conctact Email"
                  name="contactEmail"
                  value={formData.contactEmail}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
              </div>

              {/* Contact Number */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <TextField
                  label="Conctact Number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Divisor Results */}
          <Divider
            style={{
              marginTop: "20px",
            }}
          >
            RESULTS
          </Divider>

          {/* Resultados */}
          <div
            style={{
              marginTop: "10px",
            }}
          >
            {/* Sub total */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div style={{fontWeight: "bold"}}>Sub Total</div>
              <div style={{fontWeight: "bold"}}>${subTotalCost.toFixed(2)}</div>
            </div>

            {/* Tax */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div style={{fontWeight: "bold"}}>Tax (8.25%)</div>
              <div style={{fontWeight: "bold"}}>
                ${subTotalTaxes.toFixed(2)}
              </div>
            </div>

            {/* Total */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div style={{fontWeight: "bold"}}>Total (Inc. service fee)</div>
              <div style={{fontWeight: "bold"}}>${total.toFixed(2)}</div>
            </div>

            <Divider
              style={{
                marginTop: "10px",
              }}
            >
              QUOTATION
            </Divider>

            {/* Deposit payment */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div style={{fontWeight: "bold"}}>Deposit payment</div>
              <div style={{textAlign: "right"}}>
                <span style={{fontWeight: "bold"}}>$</span>
                <input
                  type="number"
                  name="advancePayment"
                  value={formData.advancePayment}
                  onChange={handleChange}
                  style={{
                    border: "none",
                    outline: "none",
                    fontWeight: "bold",
                    textAlign: "right",
                    width: "35%",
                    borderBottom: "1px solid black",
                  }}
                />
              </div>
            </div>

            {/* Remaining balance */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div style={{fontWeight: "bold"}}>Remaining balance</div>
              <div style={{fontWeight: "bold"}}>
                ${remainingBalance.toFixed(2)}
              </div>
            </div>
          </div>

          {/* Botones */}
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="contained"
              color="error"
              style={{
                backgroundColor: "#5F5F5F",
                width: "30%",
              }}
              onClick={handleClickClose}
            >
              Cancel
            </Button>
            {/* Save as Draft Button */}
            <Button
              variant="contained"
              style={{
                backgroundColor: "#9F1C28",
                width: "32%",
              }}
              onClick={() => handleCreate("tentative")}
              disabled={buttonDisabled}
            >
              Draft
            </Button>
            {/* Event Button */}
            <Button
              variant="contained"
              style={{
                backgroundColor: "#D72239",
                width: "30%",
              }}
              onClick={() => handleCreate("confirmed")}
              disabled={buttonDisabled}
            >
              Event
            </Button>
          </div>
        </div>
      </Dialog>

      {showResultDialog && (
        <ResultCalculate
          open={showResultDialog}
          setOpen={setShowResultDialog}
          formData={formData}
          selectedPackage={packages[formData.package]}
        />
      )}
    </div>
  )
}
