import React, {useState, useEffect} from "react"
import ReactDOM from "react-dom/client"
import {GoogleOAuthProvider, googleLogout} from "@react-oauth/google"
import {Login} from "./pages/Login"
import {Home} from "./pages/Home"
import "bootstrap-icons/font/bootstrap-icons.css"

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false)

  // console.log(loggedIn)

  useEffect(() => {
    const storedToken = localStorage.getItem("userData")

    if (storedToken) {
      setLoggedIn(true)
    }
  }, [])

  const handleLoginSuccess = () => {
    setLoggedIn(true)
  }

  const handleLogout = () => {
    googleLogout()
    setLoggedIn(false)
    localStorage.removeItem("token")
    localStorage.removeItem("currentTab")
    localStorage.removeItem("userData")
  }

  return (
    <div>
      {loggedIn ? (
        <Home handleLogout={handleLogout} />
      ) : (
        <Login handleLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <GoogleOAuthProvider clientId="536407439868-971gqnn8j22ssj8t3j4i58hm5js1b4nr.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
)
