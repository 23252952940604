const packages = {
  "Special Package": {
    package: "Special Package",
    label: "Special Package",
    details: "$xx per person.\nyy plates pet event.",
    unitPrice: 7.5,
    minConsumption: 60,
  },
  "Package 1": {
    package: "Package 1",
    label: "Package 1",
    details: "$xx per taco.\nyy TACOS minimum consumption.",
    unitPrice: 2.5,
    minConsumption: 400,
  },
  "Package 2": {
    package: "Package 2",
    label: "Package 2",
    details:
      "$xx per person.\nALL YOU CAN EAT TACOS!\nyy people minimum per event.",
    unitPrice: 16,
    minConsumption: 65,
  },
  "Package 3": {
    package: "Package 3",
    label: "Package 3",
    details:
      "$xx per person.\n4 TACOS EACH, CHIPS & SALSA\nyy plates minimum per event.",
    unitPrice: 12,
    minConsumption: 65,
  },
  "Package 4": {
    package: "Package 4",
    label: "Package 4",
    details:
      "$1,000 minimum total sale amount.\nJURASSIC, RAPTOR AND TREX\nSuggested for: yy person min.\n$xx each extra person.",
    unitPrice: 12.5,
    minConsumption: 100,
  },
  "Package 5": {
    package: "Package 5",
    label: "Package 5",
    details:
      "$3,995 total.\n500 Tacos and 1.5h of live music.\nTACOS & ENTERTAINMENT\n- Live music with Alex Melecio and his band.\n- Professional audio equipment included.\n- 500 street tacos with toppings & salsas.\n- 2h serving.",
    unitPrice: 0,
    minConsumption: 0,
  },
}

export default packages
