import axios from "axios"

export const getUserInfo = async () => {
  const storedToken = localStorage.getItem("token")
  try {
    const userInfoResponse = await axios.get(
      "https://www.googleapis.com/oauth2/v2/userinfo",
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    )
    localStorage.setItem("userData", JSON.stringify(userInfoResponse.data))
    const userInfo = JSON.parse(localStorage.getItem("userData"))
    return userInfo
  } catch (error) {
    console.log("Failed to get user info:", error)
  }
}
