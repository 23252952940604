import React from "react"
import AppLogo from "../../assets/App_logo.svg"
import DinoHubLogo from "../../assets/DinoHubLogo.svg"
import EventTrackerLogo from "../../assets/EventTrackerLogo.svg"
import ReportLogo from "../../assets/ReportLogo.svg"
import {Avatar} from "@mui/material"
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded"
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded"
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded"
import DiscountIcon from "@mui/icons-material/Discount"
import HomeIcon from "@mui/icons-material/Home"

export const Header = ({handleLogout, setCurrentTab, currentTab}) => {
  const userInfo = JSON.parse(localStorage.getItem("userData"))

  return (
    <div
      className="sticky-top"
      style={{
        marginTop: "15px",
        marginLeft: "15px",
        marginRight: "12px",
      }}
    >
      <nav
        className="navbar navbar-expand custom-navbar"
        style={{
          backgroundColor:
            currentTab === "reportPLOrem" ||
            currentTab === "reportPLPg" ||
            currentTab === "reportPLTrucks"
              ? "#2C8AED"
              : "#860b15",
        }}
      >
        <div className="container-fluid">
          <div
            className="collapse navbar-collapse"
            id="nav-tabs"
            role="tablist"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <button
                  className="navbar-logo ms-3 nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div
                    className="circle"
                    style={{
                      backgroundColor:
                        currentTab === "reportPLOrem" ||
                        currentTab === "reportPLPg" ||
                        currentTab === "reportPLTrucks"
                          ? "#1E5CA6"
                          : "#860b15",
                    }}
                  >
                    <img
                      src={AppLogo}
                      alt="Jurassic Tacos"
                      width="29px"
                      height="29px"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </div>
                </button>

                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={() => setCurrentTab("landing")}
                    >
                      <HomeIcon className="me-2" sx={{color: "#888"}} />
                      Home
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentTab("reportPLOrem")
                      }}
                    >
                      <AssessmentRoundedIcon
                        className="me-2"
                        sx={{color: "#888"}}
                      />
                      P&L Orem
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentTab("reportPLPg")
                      }}
                    >
                      <AssessmentRoundedIcon
                        className="me-2"
                        sx={{color: "#888"}}
                      />
                      P&L Pleasant Grove
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentTab("reportPLTrucks")
                      }}
                    >
                      <AssessmentRoundedIcon
                        className="me-2"
                        sx={{color: "#888"}}
                      />
                      P&L Trucks
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => setCurrentTab("eventList")}
                    >
                      <EventNoteRoundedIcon
                        className="me-2"
                        sx={{color: "#888"}}
                      />
                      Event Tracker
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => setCurrentTab("coupons")}
                    >
                      <DiscountIcon className="me-2" sx={{color: "#888"}} />
                      API Testing
                    </button>
                  </li>
                </ul>
              </li>

              <div className="DinoHubLogo">
                <img
                  src={DinoHubLogo}
                  style={{marginLeft: "75px", marginTop: "3px"}}
                  alt=""
                />
              </div>
              {currentTab === "eventList" && (
                <div className="EventTrackerLogo">
                  <img
                    src={EventTrackerLogo}
                    style={{marginLeft: "10px"}}
                    alt=""
                  />
                </div>
              )}
              {currentTab === "reportPLOrem" ||
                currentTab === "reportPLPg" ||
                (currentTab === "reportPLTrucks" && (
                  <div className="ReportLogo">
                    <img src={ReportLogo} style={{marginLeft: "10px"}} alt="" />
                  </div>
                ))}
            </ul>

            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle custom-icon"
                  href="#"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{display: "flex", alignItems: "center"}}
                >
                  {userInfo && (
                    <Avatar
                      src={userInfo.picture}
                      imgProps={{referrerPolicy: "no-referrer"}}
                      sx={{
                        width: 45,
                        height: 45,
                        marginTop: "1px",
                        marginRight: "-8px",
                      }}
                    />
                  )}
                  {/* {userInfo.name} */}
                  {/* <i className="bi bi-chevron-down ms-2"></i> */}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      <ExitToAppRoundedIcon
                        className="me-2"
                        sx={{color: "#888"}}
                      />
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}
