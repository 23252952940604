import React from "react"
import {useGoogleLogin} from "@react-oauth/google"
import Logo1 from "../assets/Logo1.png"
import GoogleLogo from "../assets/Google__G__Logo.png"
import {getUserInfo} from "../components/utils/getUserInfo"

export const Login = ({handleLoginSuccess}) => {
  const handleTokenSuccess = async (codeResponse) => {
    // console.log(codeResponse)
    if (codeResponse.access_token) {
      const token = codeResponse.access_token
      localStorage.setItem("token", JSON.stringify(token))
      await getUserInfo()
      handleLoginSuccess()
    }
  }

  const login = useGoogleLogin({
    clientId:
      "T818610443660-mgvq9usf5t7t4ucj1ij2c5vcfbb9bphd.apps.googleusercontent.com",
    scope: "https://www.googleapis.com/auth/calendar",
    onSuccess: handleTokenSuccess,
    onError: (error) => console.log("Login Failed:", error),
  })

  const handleSignIn = () => {
    // if (!localStorage.getItem("token")) {
    login()
    // }
  }

  return (
    <>
      {/* {!localStorage.getItem("token") && ( */}
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{height: "100vh", textAlign: "center"}}
      >
        <div
          className="logo mb-3"
          style={{animation: "logo-scale 3s infinite"}}
        >
          <img src={Logo1} style={{width: "40%"}} alt="logo" />
        </div>

        <button
          onClick={handleSignIn}
          className="btn btn-google"
          style={{
            textTransform: "none",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            transition: "box-shadow 0.3s ease-in-out",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.boxShadow =
              "0px 4px 20px rgba(0, 0, 0, 0.15)")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.boxShadow =
              "0px 4px 15px rgba(0, 0, 0, 0.1)")
          }
        >
          <img
            height="20px"
            style={{marginRight: "8px"}}
            alt="Google sign-in"
            src={GoogleLogo}
          />
          Login with Google
        </button>
      </div>
      {/* )} */}
    </>
  )
}
