import {Fragment} from "react"
import {Landing} from "../home/Landing"
import {ReportPLOrem} from "./Reports/ReportPLOrem"
import {ReportPLPg} from "./Reports/ReportPLPg"
import {ReportPLTrucks} from "./Reports/ReportPLTrucks"

import {EventList} from "./Catering/EventList"
import {Coupons} from "./Coupons/Coupons"

export const Body = ({currentTab}) => {
  const defaultStyle = {
    marginTop: "15px",
    marginLeft: "15px",
    marginRight: "15px",
  }

  const renderTab = () => {
    switch (currentTab) {
      case "landing":
        return (
          <div style={defaultStyle}>
            <Landing />
          </div>
        )
      case "reportPLOrem":
        return (
          <div style={defaultStyle}>
            <ReportPLOrem />
          </div>
        )
      case "reportPLPg":
        return (
          <div style={defaultStyle}>
            <ReportPLPg />
          </div>
        )
      case "reportPLTrucks":
        return (
          <div style={defaultStyle}>
            <ReportPLTrucks />
          </div>
        )
      case "eventList":
        return (
          <div style={defaultStyle}>
            <EventList currentTab={currentTab} />
          </div>
        )
      case "coupons":
        return (
          <div style={defaultStyle}>
            <Coupons />
          </div>
        )
      default:
        return (
          <div style={defaultStyle}>
            <Landing />
          </div>
        )
    }
  }

  return (
    <Fragment>
      <div>{renderTab()}</div>
    </Fragment>
  )
}
