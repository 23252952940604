import axios from "axios"
import {getNewToken} from "../utils/getNewToken"
import dayjs from "dayjs"
import terms from "../home/Catering/Terms"

const handleDataBase = (terms, data) => {
  const newData = []

  for (let key in terms) {
    if (data.hasOwnProperty(key)) {
      newData.push(`${terms[key]}: ${data[key]}`)
    }
  }

  return newData.join("\n")
}

export const pushNewEvent = async (data, eventStatus) => {
  // console.log(data)
  const result = handleDataBase(terms, data)
  // console.log(result)
  const storedToken = await getNewToken()
  try {
    const createEventResponse = await axios.post(
      "https://www.googleapis.com/calendar/v3/calendars/c_23c62d9fff414e2cc457d33721fa48ed52fa30297197a4479dedeecd48cce7e2%40group.calendar.google.com/events",
      {
        end: {
          dateTime: data.endDate,
          timeZone: "America/Denver",
        },
        start: {
          dateTime: data.startDate,
          timeZone: "America/Denver",
        },
        status: eventStatus,
        summary: data.summary,
        description: result,
        customProperty: data.image,
      },
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    const createResponse = createEventResponse.status
    // console.log(createResponse)
    return createResponse
  } catch (error) {
    // console.log("Failed to create event:", error)
  }
}

export const pushEditEvent = async (data, eventStatus) => {
  const result = handleDataBase(terms, data)
  console.log(data)
  const storedToken = await getNewToken()
  try {
    const createEventResponse = await axios.put(
      "https://www.googleapis.com/calendar/v3/calendars/c_23c62d9fff414e2cc457d33721fa48ed52fa30297197a4479dedeecd48cce7e2@group.calendar.google.com/events/" +
        data.id,
      {
        end: {
          dateTime: data.endDate,
          timeZone: "America/Denver",
        },
        start: {
          dateTime: data.startDate,
          timeZone: "America/Denver",
        },
        status: eventStatus,
        summary: data.summary,
        description: result,
        // customProperty: data.image,
      },
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    const createResponse = createEventResponse.status
    // console.log(createResponse)
    return createResponse
  } catch (error) {
    console.log("Failed to create event:", error)
  }
}
